import { DynamicImage, PrimaryButton, Text } from 'components';
import AppleStoreButton from 'pages/start/success/components/AppleStoreButton';
import GooglePlayButton from 'pages/start/success/components/GooglePlayButton';
import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, smMobile, tablet } from 'styles/breakpoints';
import Success from 'assets/icons/success.svg';
import Disclaimer from 'components/Disclaimer';
import { config } from 'config';

interface RegisterCompletedProps {}

const DownloadContainer = styled.div`
  width: 100%;
  background-color: #f3f3f8;
  padding: 3.5rem 1rem 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 138px);
  @media ${tablet} {
    padding: 2.375rem 1rem;
    height: calc(100vh - 132px);
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DynamicImageStyled = styled(DynamicImage)`
  max-width: 26.79688rem;
  height: 20rem;
  width: 100%;
  @media ${tablet} {
    height: unset;
  }
`;

const Title = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 34.0625rem;
  padding: 1.5rem 0;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
`;

const ButtonStyled = styled(PrimaryButton)`
  max-width: 28.75rem;
  width: 100%;
`;

const RegisterCompleted: FC<RegisterCompletedProps> = () => (
  <>
    <DownloadContainer>
      <ImgContainer>
        <DynamicImageStyled src="upsell/app.png" alt="Moongrade app" />
        <Title>Thank you for purchasing the Moongrade app! 🎉</Title>
        <ButtonStyled
          onClick={() => window.open(config.WEB_APP_URL, '_system')}
        >
          Install Nordastro App
        </ButtonStyled>
      </ImgContainer>
    </DownloadContainer>
    <Disclaimer bgHex="#fff" text="© 2024 Nordastro. All rights reserved." />
  </>
);

export default RegisterCompleted;
